.a-searchinput {
	$root: &;
	align-items: center;
	background-color: $color-white;
	border-radius: rem(5);
	display: flex;
	padding: rem(29) rem(31);

	&_textinput {
		appearance: none;
		border: 0;
		color: $color-arsenic2;
		font-size: rem(20);
		line-height: rem(24);
		margin-left: rem(17);
		outline: none;
		padding: 0;
		width: 100%;

		&:focus {
			outline: 0;
		}
	}

	.a-icon {
		flex: 0 0 auto;
		height: rem(24);
		width: rem(24);
	}
}
