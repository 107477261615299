.m-descriptionimage {
	$root: &;
	position: relative;

	&_description {
		background: $color-white-085;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		transition: all 300ms ease-in-out;
		width: 100%;
		z-index: z('normal');

		@include greater-than-md {
			opacity: 0;
			overflow: hidden;
			overflow: hidden;
			transform: translateY(100%);
			visibility: hidden;

			#{$root}:hover & {
				opacity: 1;
				overflow: visible;
				transform: translateY(0);
				visibility: visible;
			}
		}

		.a-text {
			color: $color-dim-gray2;
			font-family: $font-family-basic;
			padding: rem(16) rem(19);

			#{$root}-response & {
				color: $color-arsenic2;
				padding: rem(18) rem(32) rem(9);
			}

			#{$root}-ourteam & {
				color: $color-arsenic2;
				padding: rem(12) rem(30);
			}
		}
	}
}
