.m-ourvaluescard {
	$root: &;
	height: 100%;
	overflow: hidden;
	position: relative;

	&_content {
		background-color: $color-snow-08;
		bottom: rem(86);
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rem(20);
		position: absolute;
		transform: translateY(100%);
		transition: all .4s ease-in-out;
		width: 100%;
		z-index: z('ourValuesCard', 'content');

		#{$root}:hover & {
			bottom: 0;
			height: 100%;
			justify-content: flex-end;
			transform: translateY(0);
		}

		#{$root}-large & {
			bottom: rem(88);
		}

		#{$root}-largest & {
			bottom: rem(100);
		}
	}

	&_title {
		align-items: center;
		display: flex;
		height: rem(52);

		.a-text {
			color: $color-arsenic2;
			margin-bottom: rem(4);
			@include text-overflow;
		}

		.a-icon {
			flex: 0 0 auto;
			margin-left: rem(17);
		}

		#{$root}-large & {
			height: rem(54);

			.a-text {
				font-family: $font-family-basic;
				font-size: rem(20);
				line-height: rem(24);
			}
		}

		#{$root}-largest & {
			height: rem(66);

			.a-text {
				font-family: $font-family-basic;
				font-size: rem(24);
				line-height: rem(30);
				@include u-fw-medium;
			}
		}
	}

	&_description {
		color: $color-dim-gray2;
		margin-top: rem(13);

		@media (min-width: $width-lg) and (max-width: $width-tab) {
			@include text-overflow (6);
		}

		@media (min-width: $width-md) and (max-width: $width-lg - 1) {
			@include text-overflow (4);
		}
	}

	&_sublink {
		align-items: center;
		display: flex;
		margin-top: rem(14);

		&:hover {
			color: $color-french-plum;
		}

		.a-text {
			color: $color-french-plum;
			font-family: $font-family-basic;
			line-height: rem(24);
			margin-bottom: 0;
		}

		.a-icon {
			flex: 0 0 auto;
			margin-left: rem(17);
		}
	}

	.a-image {
		height: 100%;
	}
}
