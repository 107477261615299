.m-listitem {
	$root: &;

	&_wrapper {
		height: 100%;
		padding: rem(10) rem(25);
	}

	&_field {
		& + & {
			margin-top: rem(40);
		}
	}

	&_title {
		color: $color-arsenic2;
	}

	&_description {
		.a-text {
			border-bottom: solid 1px $color-silver-chalice;
			font-family: $font-family-basic;
			padding: rem(10) 0;

			&:not(:last-child) {
				margin-bottom: rem(28);
			}
		}
	}

	&-statistic {
		list-style: none;
	}
}
