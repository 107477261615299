/* stylelint-disable declaration-no-important */
.u-scrollbox {
	$root: &;
	height: 100%;
	position: relative;

	&_inner {
		bottom: 0;
		display: flex;
		flex-direction: column;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		#{$root}-no-scroll-xs & {
			@media (max-width: $width-sm - 1) {
				position: relative !important;
			}
		}

		#{$root}-no-scroll-sm & {
			@media (max-width: $width-md - 1) {
				position: relative !important;
			}
		}

		#{$root}-no-scroll-md & {
			@media (max-width: $width-lg - 1) {
				position: relative !important;
			}
		}

		#{$root}-no-scroll-lg & {
			@media (max-width: $width-xl - 1) {
				position: relative !important;
			}
		}
	}

	&_content {
		flex: 1;
		overflow-y: auto;
		padding-right: 5px;
		@include scrollbars(2px, $color-gainsboro, $color-white);

		&::-webkit-scrollbar-thumb {
			&:hover {
				background-color: $color-french-plum;
			}
		}

		#{$root}-no-scroll-xs & {
			@media (max-width: $width-sm - 1) {
				overflow-y: inherit;
				padding-right: 0;
			}
		}

		#{$root}-no-scroll-sm & {
			@media (max-width: $width-md - 1) {
				overflow-y: inherit;
				padding-right: 0;
			}
		}

		#{$root}-no-scroll-md & {
			@media (max-width: $width-lg - 1) {
				overflow-y: inherit;
				padding-right: 0;
			}
		}

		#{$root}-no-scroll-lg & {
			@media (max-width: $width-xl - 1) {
				overflow-y: inherit;
				padding-right: 0;
			}
		}
	}

	&-no-scroll-xs {
		@media (max-width: $width-sm - 1) {
			padding-top: 0 !important;
		}
	}

	&-no-scroll-sm {
		@media (max-width: $width-md - 1) {
			padding-top: 0 !important;
		}
	}

	&-no-scroll-md {
		@media (max-width: $width-lg - 1) {
			padding-top: 0 !important;
		}
	}

	&-no-scroll-lg {
		@media (max-width: $width-xl - 1) {
			padding-top: 0 !important;
		}
	}
}
