.m-infoblock {
	background-color: $color-white;
	padding: rem(30) rem(24) rem(30) rem(25);

	&_description {
		color: $color-dim-gray2;
		font-family: $font-family-helveticaworld;
		margin-bottom: rem(20);
	}

	.a-heading {
		color: $color-arsenic;
		margin-bottom: rem(15);
	}
}
