// @see https://stackoverflow.com/a/33687499/9918677

// FONT FAMILY: Bitter
@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 900;
	src: url('~assets/fonts/Bitter-Black.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 900;
	src: url('~assets/fonts/Bitter-BlackItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 700;
	src: url('~assets/fonts/Bitter-Bold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 700;
	src: url('~assets/fonts/Bitter-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 800;
	src: url('~assets/fonts/Bitter-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 800;
	src: url('~assets/fonts/Bitter-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 200;
	src: url('~assets/fonts/Bitter-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 200;
	src: url('~assets/fonts/Bitter-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: normal;
	src: url('~assets/fonts/Bitter-Italic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 300;
	src: url('~assets/fonts/Bitter-Light.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 300;
	src: url('~assets/fonts/Bitter-LightItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 500;
	src: url('~assets/fonts/Bitter-Medium.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 500;
	src: url('~assets/fonts/Bitter-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 400;
	src: url('~assets/fonts/Bitter-Regular.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 600;
	src: url('~assets/fonts/Bitter-SemiBold.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 600;
	src: url('~assets/fonts/Bitter-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 100;
	src: url('~assets/fonts/Bitter-Thin.ttf') format('truetype');
}

@font-face {
	font-display: swap;
	font-family: 'Bitter';
	font-style: italic;
	font-weight: 100;
	src: url('~assets/fonts/Bitter-ThinItalic.ttf') format('truetype');
}

// FONT FAMILY: HelveticaWorld
@font-face {
	font-display: swap;
	font-family: 'HelveticaWorld';
	font-style: normal;
	font-weight: normal;
	src: url('~assets/fonts/HelveticaWorld-Regular.woff2') format('woff2'), url('~assets/fonts/HelveticaWorld-Regular.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'HelveticaWorld';
	font-style: normal;
	font-weight: 700;
	src: url('~assets/fonts/HelveticaWorld-Bold.woff2') format('woff2'), url('~assets/fonts/HelveticaWorld-Bold.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'HelveticaWorld';
	font-style: italic;
	font-weight: normal;
	src: url('~assets/fonts/HelveticaWorld-Italic.woff2') format('woff2'), url('~assets/fonts/HelveticaWorld-Italic.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: 'HelveticaWorld-BoldIt';
	font-style: italic;
	font-weight: 700;
	src: url('~assets/fonts/HelveticaWorld-BoldIt.woff2') format('woff2'), url('~assets/fonts/HelveticaWorld-BoldIt.woff') format('woff');
}
