.a-text {
	color: $color-dim-gray2;
	font-family: $font-family-helveticaworld;
	font-size: rem(16);
	line-height: rem(23);

	&-font-weight-medium {
		@include u-fw-medium;
	}

	&-font-weight-semibold {
		@include u-fw-semibold;
	}

	&-font-weight-bold {
		@include u-fw-bold;
	}

	&-font-style-italic {
		@include u-fs-italic;
	}

	&-size-large {
		font-size: rem(18);
		line-height: rem(26);
	}

	&-size-small {
		font-size: rem(14);
		line-height: rem(20);
	}

	&-size-xsmall {
		font-size: rem(12);
		line-height: rem(18);
	}

	&-center {
		text-align: center;
	}

	&-left {
		text-align: left;
	}

	&-right {
		text-align: right;
	}

	&-justify {
		text-align: justify;
	}
}
