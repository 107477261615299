.m-mediacontacts {
	$root: &;

	&_heading {
		margin-bottom: rem(24);

		.a-heading {
			color: $color-arsenic2;
		}
	}

	&_mailwrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 rem(-10) rem(20);
	}

	&_mailtitle {
		flex: 0 0 calc(100%/3);
		max-width: calc(100%/3);
		padding: 0 rem(10);

		@include tab-down() {
			flex: 0 0 100%;
			max-width: 100%;
		}

		.a-text {
			color: $color-dim-gray;
		}
	}

	&_mailto {
		align-items: center;
		display: flex;
		padding: 0 rem(10);

		@include tab-down() {
			margin-top: rem(20);
		}

		a {
			display: block;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}

		.a-text {
			color: $color-brandeis-blue;
		}

		.a-icon {
			height: rem(24);
			margin-right: rem(12);
			width: rem(34);
		}
	}

	&_list {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 0 rem(-10);
	}

	&_item {
		flex: 0 0 calc(100%/3);
		margin-top: rem(20);
		max-width: calc(100%/3);
		padding: 0 rem(10);

		@include tab-down() {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	&_name {
		color: $color-arsenic;
		margin-bottom: rem(20);
	}

	&_position {
		color: $color-dim-gray2;
		margin-bottom: rem(12);
	}

	&_phone {
		align-items: center;
		display: flex;

		a {
			color: $color-dim-gray2;
		}

		.a-icon {
			height: rem(25);
			margin-right: rem(5);
			width: rem(25);
		}
	}
}
