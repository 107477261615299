.o-map {
	height: 100%;
	width: 100%;

	/* stylelint-disable */
	.gmnoprint,
	.gm-fullscreen-control {
		display: none !important;
	}

	&_marker {
    position: relative;
  }

	&_pin {
    width: rem(30);
    height: rem(30);
    background-image: url("~assets/images/system/map-marker.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translate(-50%, -50%);
  }
}
