.a-divider {
	width: 100%;

	&-solid {
		border-bottom: 1px solid $color-silver-chalice;
	}

	&-dashed {
		border-bottom: 1px dashed $color-silver-chalice;
	}
}
