.o-modal {
	$root: &;
	$content: #{$root}_content;

	&_overlay {
		background: $color-black-065;
		bottom: 0;
		content: '';
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: z('modal', 'base');

		&-useToStoreVideo {
			align-items: center;
			display: flex;
		}
	}

	&_close {
		background-color: $color-dim-gray2;
		cursor: pointer;
		float: right;
		padding: rem(17);
		text-align: center;
		width: rem(60);

		#{$content}-closableWithHover & {
			opacity: 0;
			position: absolute;
			right: 0;
			transition: .3s ease-in-out;
			visibility: hidden;
			z-index: z('modal', 'closebutton');
		}

		#{$content}-closableWithHover:hover & {
			opacity: 1;
			visibility: visible;
		}

		@include tab-down {
			padding: rem(15);
			width: rem(54);
		}

		@include sp-medium-down {
			padding: rem(10.5);
			width: rem(45);
		}

		.a-icon {
			height: rem(23);
			width: rem(23);

			@include tab-down {
				height: rem(20);
				width: rem(20);
			}

			@include sp-medium-down {
				height: rem(17);
				width: rem(17);
			}
		}
	}

	&_content {
		appearance: none;
		background-color: $color-white;
		height: max-content;
		left: 0;
		margin: 0 auto;
		max-height: calc(100% - 40px);
		outline: none;
		overflow: hidden auto;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include tab-up {
			left: rem(40);
			right: rem(40);
		}

		&-useToStoreVideo {
			top: unset;
			transform: unset;
		}

		@include pc-up {
			max-height: calc(100% - 80px);
		}
	}

	&_body {
		color: $color-dim-gray;
		font-size: rem(24);
		line-height: rem(30);
		padding: rem(68) rem(100) rem(40);
		@include u-fw-medium;

		@include tab-down {
			padding: rem(32) rem(50);
		}

		@include sp-medium-down {
			padding: rem(24) rem(21) rem(31);
		}

		#{$content}-closable & {
			flex: 1;
			overflow: hidden auto;
		}

		#{$content}-noSpacing & {
			padding: 0;
		}

		#{$content}-workplaceslider & {
			padding: rem(64);

			@include tab-down {
				padding: rem(32);
			}

			@include sp-medium-down {
				padding: rem(24);
			}
		}
	}
}
