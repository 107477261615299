.m-immunogeicityblock {
	$root: &;

	&-article-news {
		border-bottom: 1px solid $color-gainsboro;
		display: flex;
		padding-bottom: rem(20);

		@include sp-down {
			flex-direction: column;
		}
		#{$root}_thumbnail {
			flex: 0 0 rem(240);
			@include sp-down {
				flex: 0 0 100%;
			}
		}
		#{$root}_content {
			flex-grow: 1;
			margin-left: rem(32);
			@include sp-down {
				margin-left: 0;
				margin-top: rem(16);
			}
		}
	}

	&_content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: flex-end;
	}

	&_title {
		.a-text {
			#{$root}-press-releases & {
				color: $color-arsenic2;
				font-size: rem(18);
				line-height: rem(26);
			}
			#{$root}-article-news & {
				color: $color-arsenic2;
				font-family: $font-family-basic;
				font-size: rem(18);
				line-height: rem(26);
			}
		}
	}

	&_datetime {
		margin-top: rem(15);

		.a-text {
			color: $color-taupe-gray;

			#{$root}-press-releases & {
				color: $color-dim-gray2;
				@include u-fs-italic;
			}

			#{$root}-article-news & {
				color: $color-dim-gray2;
				@include u-fs-italic;
			}
		}
	}

	&_divider {
		background-color: $color-taupe-gray;
		display: block;
		height: rem(.5);
		margin-top: rem(15);
		#{$root}-article-news & {
			display: none;
		}

		#{$root}-press-releases & {
			background-color: $color-gainsboro;
			height: rem(1);
		}
	}
}
