html {
	font-size: $font-size-html;
	word-break: break-word;
}

body {
	color: $color-black;
	font-family: $font-family-basic;
	margin: 0;
	overflow-x: hidden;
}

// Default a-text style for p tags
p {
	font-size: rem($font-size-html-value);

	&:last-child {
		margin-bottom: 0;
	}
}

// Default a-textlink style for a tags
a {
	color: $color-brandeis-blue;
	text-decoration: none;

	&:hover {
		color: $color-brandeis-blue;
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}

button,
select {
	font-family: inherit;

	&:focus {
		appearance: none;
		border: 0;
		outline: none;
	}
}

[role='button'],
button {
	cursor: pointer;
}

ul,
ol {
	margin: 0;
	padding: 0;
}

b,
strong {
	@include u-fw-bold;
}

.u-textlink-frenchplum {
	&,
	&:hover {
		color: $color-french-plum;
	}
}

// Default text-underline style for download file
.u-download-file {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.u-page-detail {

	@include tab-up {
		display: flex;
		flex-wrap: wrap;
	}

	&_publish {
		max-width: rem(193);

		@include tab-up {
			flex: 0 0 calc(193 / #{$width-container-value} * 100%);
			padding-right: rem(32);
		}

		@include sp-medium-down {
			margin-bottom: rem(20);
		}

		.a-text {
			margin-bottom: rem(5);
		}
	}

	&_content {
		@include tab-up {
			flex: 0 0 calc(100% - (386  / #{$width-container-value} * 100%));
			max-width: calc(100% - (386  / #{$width-container-value} * 100%));
		}
	}
}

.u-scrollbar-show-on-mobile {
	overflow: auto hidden;

	@include scrollbars(6px, $color-gainsboro, $color-white);

	&::-webkit-scrollbar {
		// Fix safari
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
		-webkit-overflow-scrolling: touch;
	}

	&::-webkit-scrollbar-thumb {
		&:hover {
			background-color: $color-french-plum;
		}
	}
}

$offsets: (top, bottom, left, right);
$breakpoints: (
	sm: '(min-width: 576px)',
	md: '(min-width: 768px)',
	lg: '(min-width: 992px)',
	xl: '(min-width: 1200px)',
);

@each $offset in $offsets {
	@for $spacing from -20 through 20 {
		@if $spacing >= 0 {
			.u-m#{str-slice($offset, 0, 1)}-#{$spacing} {
				margin-#{$offset}: #{$spacing}px;
			}
			.u-p#{str-slice($offset, 0, 1)}-#{$spacing} {
				padding-#{$offset}: #{$spacing}px;
			}
		}
		@else {
			.u-m#{str-slice($offset, 0, 1)}-negative#{$spacing} {
				margin-#{$offset}: #{$spacing}px;
			}
		}
	}
}

@each $name, $value in $breakpoints {
	@each $offset in $offsets {
		@for $spacing from -20 through 20 {
			@if $spacing >= 0 {
				.u-m#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
					@media #{$value} {
						margin-#{$offset}: #{$spacing}px;
					}
				}

				.u-p#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
					@media #{$value} {
						padding-#{$offset}: #{$spacing}px;
					}
				}
			}
			@else {
				.u-m#{str-slice($offset, 0, 1)}-negative-#{$name}#{$spacing} {
					@media #{$value} {
						margin-#{$offset}: #{$spacing}px;
					}
				}
			}
		}
	}
}

// Set the background-color of the body Iframe to transparent
.sb-show-main {
	background-color: $color-transparent;
}

.u-pl-30 {
	padding-left: rem(30);
}

.u-iframewrapper {
	iframe {
		height: 100%;
		width: 100%;
	}
}
