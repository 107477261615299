.a-image {
	$root: &;
	overflow: hidden;
	position: relative;

	&_img {
		max-width: 100%;

		#{$root}-fixed &,
		#{$root}-oversize &,
		#{$root}-circled & {
			display: block;
			height: 100%;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}

		#{$root}-fixed & {
			object-fit: cover;
			width: 100%;
		}

		#{$root}-oversize & {
			object-fit: cover;
			object-position: top;
		}

		#{$root}-circled & {
			border-radius: 50%;
			object-fit: cover;
			width: 100%;
		}

		#{$root}-fullwidth & {
			width: 100%;
		}
	}

	&-center {
		text-align: center;
	}

	&-fixed {
		background-color: $color-arsenic;
	}

	&-transparent {
		background-color: $color-transparent;
	}

	&-videothumb {
		position: relative;

		&::after {
			background: $color-black-025;
			bottom: 0;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			width: 100%;
		}

		.a-icon {
			height: rem(40);
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: rem(40);
			z-index: z('normal');
		}
	}

	&-16x9 {
		@include aspect-ratio(16, 9);
	}

	&-1x1 {
		@include aspect-ratio(1, 1);
	}

	&-2x1 {
		@include aspect-ratio(2, 1);
	}

	&-3x2 {
		@include aspect-ratio(3, 2);
	}

	&-3x4 {
		@include aspect-ratio(3, 4);
	}

	&-4x3 {
		@include aspect-ratio(4, 3);
	}

	&-award {
		@include aspect-ratio(317, 211);
	}

	&-activity {
		@include aspect-ratio(546, 368);
	}

	&-report {
		@include aspect-ratio(547, 280);
	}

	&-latestnews {
		@include aspect-ratio(354, 200);
	}

	&-environmentalprotection,
	&-sustainabilitycommitment {
		@include aspect-ratio(546, 320);
	}

	&-response {
		@include aspect-ratio(547, 200);
	}

	&-captionblockprimary {
		@include aspect-ratio(740, 416);
	}

	&-captionblocksecondary {
		@include aspect-ratio(354, 192);
	}

	&-workplaceslider {
		@include aspect-ratio(450, 290);
	}

	&-companynews {
		@include aspect-ratio(354, 200);
	}

	&-timeline {
		@include aspect-ratio(166, 90);
	}

	&-vaccines {
		@include aspect-ratio(354, 220);
	}

	&-ourteam {
		@include aspect-ratio(362, 242);
	}

	&-sustainability {
		@include aspect-ratio(741, 415);
	}

	&-workplaceslidercomment {
		@include aspect-ratio(253, 236);
	}

	&-workplaceslidermodal {
		@include aspect-ratio(253, 230);
	}

	&-hearfromourteam {
		@include aspect-ratio(547, 468);
	}

	&-ourcovid19vaccine {
		@include aspect-ratio(547, 242);
	}

	&-healthylungprogramme {
		@include aspect-ratio(546, 252);
	}

	&-younghealthprogrammesingle {
		@include aspect-ratio(546, 210);
	}

	&-younghealthprogramme {
		@include aspect-ratio(354, 236);
	}

	&-careme {
		@include aspect-ratio(544, 300);
	}

	&-ourjourneyinvietnam {
		@include aspect-ratio(547, 390);
	}

	&-articlenews {
		@include aspect-ratio(160, 108);
	}
}
