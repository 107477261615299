/*
z-index manager

usage:
1. add z-index definition into $layers
2. call it as follows
   z-index: z("example"); => 1
   z-index: z("example-group", "base"); => 1
--------------------------------------------------------- */

$layers: (
	normal: 1,
	modal: (
		base: 9000,
		closebutton: 8999,
	),
	header: (
		headerwrapper: 900,
		searchbar: -1,
		mainmenuunderline: 2,
	),
	surveyResults: (
		number: 2,
		tooltip:3,
	),
	latestNewsSlider: (
		swiperbutton: 2,
	),
	timeline: (
		after: 2,
	),
	ourValuesCard: (
		content: 2,
	),
	banner: (
		player: 2,
		content: 4,
		modal: 5,
	),
);

@function map-deep-get($map, $keys...) {
	$value: $map;

	@each $key in $keys {
		$value: map-get($value, $key);
	}

	@return $value;
}

@function z($keys...) {
	@if not variable-exists(layers) {
		@error '`$layers` is not defined.';
	}

	@if not function-exists(map-deep-get) {
		@error '`map-deep-get()` is not available.';
	}

	@return map-deep-get($layers, $keys...);
}
