.a-button {
	$root: &;
	$animation: .3s cubic-bezier(.4, 0, .2, 1);
	align-items: center;
	appearance: none;
	background-color: $color-transparent;
	border: rem(1) solid $color-taupe-gray;
	color: $color-arsenic;
	display: flex;
	font-family: $font-family-basic;
	font-size: rem(16);
	justify-content: center;
	line-height: rem(24);
	outline: none;
	padding: rem(16) rem(25) rem(16) rem(30);
	transition: all $animation;
	@include u-fs-italic;
	@include u-fw-bold;

	&:hover,
	&:focus,
	&:focus-within {
		border: rem(1) solid $color-taupe-gray;
		text-decoration: underline;
	}

	&:hover {
		color: $color-arsenic;
	}

	&_iconwrapper {
		margin-left: rem(17);
		pointer-events: none;
	}

	&-disabled {
		opacity: .7;
		pointer-events: none;
	}

	&-fullWidth {
		width: 100%;
	}

	&-outline {
		border: 0;
		padding: 0;

		&:hover,
		&:focus,
		&:focus-within {
			border: 0;
		}
	}

	&-background-color-french-plum {
		background-color: $color-french-plum;
		border: rem(1) solid $color-french-plum;
		color: $color-white;

		&:hover,
		&:focus,
		&:focus-within {
			border: rem(1) solid $color-french-plum;
			color: $color-white;
		}
	}
}

/* stylelint-disable selector-no-qualifying-type */
a[class*='a-button'] {
	display: inline-flex;
}
