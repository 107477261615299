.m-joinourteam {
	$root: &;
	height: auto;
	overflow: hidden;
	position: relative;

	@include tab-up {
		display: flex;
		height: 100%;
	}

	.a-image {
		height: 100%;

		@include tab-up {
			width: 50%;
		}
	}

	&_content {
		background: $color-french-plum;
		padding: rem(40) rem(30);

		@include tab-up {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-bottom: rem(16);
			padding-top: rem(16);
			width: 50%;
			z-index: z('normal');
		}

		.a-heading_text {
			color: $color-white;
		}
	}

	&_title {
		@include tab-up {
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.a-text {
		margin-bottom: rem(20);
		margin-top: rem(5);
	}

	.a-text,
	.a-button {
		color: $color-white;
	}
}
