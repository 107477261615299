.m-surveyresults {
	$root: &;

	&_circular {
		border-bottom: solid 2px $color-taupe-gray;
		display: flex;
		margin-bottom: rem(14);
		padding-bottom: rem(19);
	}

	&_item {
		position: relative;

		& + & {
			margin-left: rem(50);
		}
	}

	&_wrapitem {
		border-radius: 100%;
		cursor: pointer;
		height: rem(75);
		position: relative;
		width: rem(75);
	}

	&_inner {
		border-radius: 100%;
		height: rem(58);
		left: 50%;
		margin: rem(-29) 0 0 rem(-29);
		position: absolute;
		top: 50%;
		width: rem(58);
		z-index: z('normal');
	}

	&_number {
		left: 50%;
		line-height: rem(24);
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: z('surveyResults', 'number');

		.a-text {
			color: $color-white;
			margin-bottom: 0;
		}
	}

	&_title {
		background: $color-white;
		border-radius: rem(10);
		box-shadow: 0 0 rem(15) $color-black-015;
		display: none;
		left: 0;
		padding: rem(10) rem(30);
		pointer-events: none;
		position: absolute;
		top: rem(20);
		transform: translate(0, -100%);
		transition: all .3s ease-in-out;
		white-space: nowrap;
		z-index: z('surveyResults', 'tooltip');

		#{$root}_item:hover & {
			display: block;
		}

		.a-text {
			color: $color-arsenic;
			letter-spacing: .05em;
		}
	}

	&_description {
		.a-text {
			color: $color-arsenic;
			letter-spacing: .05em;
		}
	}
}
