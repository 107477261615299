/*
* https://hexcol.com/
*	description: Use this tool to get the Color-Name
*/

/* #region COLOR-BASE */
$color-transparent: transparent;
$color-black: rgb(0, 0, 0);
$color-black-015: rgba(0, 0, 0, .15);
$color-black-025: rgba(0, 0, 0, .25);
$color-black-065: rgba(0, 0, 0, .65);
$color-white: rgb(255, 255, 255);
$color-white-085: rgba(255, 255, 255, .85);
$color-white-smoke: rgb(245, 245, 245);
$color-arsenic: rgb(63, 68, 68);
$color-arsenic2: rgb(60, 66, 66);
$color-arsenic3: rgb(61, 67, 67);
$color-arsenic4: rgb(61, 66, 67);
$color-anti-flash-white: rgb(242, 242, 242);
$color-dim-gray: rgb(99, 99, 99);
$color-dim-gray2: rgb(99, 104, 104);
$color-dark-liver: rgb(79, 79, 79);
$color-platinum: rgb(224, 224, 224);
$color-gainsboro: rgb(216, 217, 217);
$color-taupe-gray: rgb(138, 142, 142);
$color-french-plum: rgb(131, 0, 81);
$color-brandeis-blue: rgb(0, 107, 255);
$color-battleship-grey: rgb(130, 130, 130);
$color-snow: rgb(250, 250, 250);
$color-snow-08: rgba($color-snow, .8);
$color-silver-chalice: rgb(177, 179, 179);
$color-silver-chalice-025: rgba($color-silver-chalice, .25);
$color-spanish-gray: rgb(149, 149, 149);
$color-eerie-black: rgb(27, 27, 27);
$color-x11-gray: rgb(189, 189, 189);
$color-quinacridone-magenta: rgb(151, 49, 97);
$color-gray: rgb(129, 129, 129);
$color-gray-025: rgba($color-gray, .25);
$color-white-smoke: rgb(247, 247, 247);
$color-sonic-silver: rgb(117, 117, 117);
/* #endregion */

/* #region GRADIENT COLOR */
$color-banner-background: linear-gradient(to right, rgba(27, 27, 27, .7), rgba(27, 27, 27, 0) 60%);
/* #endregion */

/* #region FONT-FAMILY */
$font-family-basic: 'Bitter';
$font-family-helveticaworld: 'HelveticaWorld';
$font-family-roboto: 'Roboto';
/* #endregion */

/* #region FONT-SIZE html */
$font-size-html-value: 16;
$font-size-html: 16px;
/* #endregion */

/* #region SIZE-SCREEN */
$width-container-value: 1126;
$width-container: 1126px;
$width-pc-large: 1920px;
$width-pc-medium: 1366px;
$width-pc: 1280px;
$width-tab: 1024px;
$width-xl: 1200px;
$width-lg: 992px;
$width-md: 768px;
$width-sm: 576px;
/* #endregion */
