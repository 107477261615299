.m-informationcard {
	$root: &;
	display: flex;
	flex-direction: column;
	height: 100%;

	&_content {
		flex: 1;

		#{$root}-award & {
			background-color: $color-snow;
			padding: rem(20);
		}

		#{$root}-activity & {
			margin-top: rem(15);
		}

		#{$root}-latestnews & {
			margin-top: rem(24);
		}

		#{$root}-companynews & {
			margin-top: rem(20);
		}

		#{$root}-vaccines & {
			background-color: $color-white;
			padding: rem(25) rem(18);
		}
	}

	&_tagname {
		margin-bottom: rem(13);
	}

	&_date {
		margin-bottom: rem(8);
	}

	&_heading {
		margin-bottom: rem(10);

		.a-text {
			color: $color-arsenic;
		}

		#{$root}-vaccines & {
			margin-bottom: rem(25);

			.a-text {
				color: $color-arsenic2;
			}
		}

		#{$root}-activity & {
			.a-text {
				font-family: $font-family-basic;
			}
		}
	}

	&_subtitle {
		margin-bottom: rem(10);

		.a-text {
			color: $color-french-plum;
		}
	}

	&_tagname,
	&_date,
	&_heading,
	&_subtitle,
	&_description {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&-vaccines {
		box-shadow: 0 0 5px $color-black-015;
	}

	.a-button {
		margin-top: rem(20);
	}
}
