.m-latestnewsslider {
	$root: &;
	position: relative;

	&_item {
		height: auto;

		.m-informationcard {
			display: flex;
			flex-direction: column;
			height: 100%;

			&_content {
				flex: 1;
			}
		}
	}

	&_direction {
		@include pc-down {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin-top: rem(20);
		}
	}

	&_pagination {
		display: flex;

		@include pc-medium-up {
			justify-content: center;
			margin-top: rem(52);
		}

		&.swiper-pagination-lock {
			display: none;
		}
	}

	&_bullet {
		background: $color-x11-gray;
		border-radius: 50%;
		cursor: pointer;
		display: block;
		height: rem(12);
		width: rem(12);

		& + & {
			margin-left: rem(8);
		}

		&-active {
			background-color: $color-french-plum;
		}
	}

	&_navigation {
		@include pc-down {
			display: flex;
			justify-content: flex-end;
		}
	}

	&_next,
	&_prev {
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
		height: rem(32);
		transition: opacity .3s ease-in-out;
		width: rem(32);
		z-index: z('latestNewsSlider', 'swiperbutton');

		@include pc-medium-up {
			position: absolute;
			top: calc(50% - #{rem(64)});
			transform: translateY(-50%);
		}

		@include pc-down {
			display: flex;
			justify-content: flex-end;
		}

		&:hover {
			opacity: .6;
		}

		&:focus {
			outline: none;
		}

		&.swiper-button-disabled {
			cursor: unset;
			opacity: .5;
			pointer-events: none;
		}

		&.swiper-button-lock {
			display: none;
		}
	}

	&_next {
		background-image: url('~assets/images/icons/right-french-plum-arrow.svg');
		right: 0;

		@include pc-medium-up {
			right: rem(-46);
		}

		@include pc-down {
			margin-left: rem(18);
		}
	}

	&_prev {
		background-image: url('~assets/images/icons/left-french-plum-arrow.svg');
		left: 0;

		@include pc-medium-up {
			left: rem(-46);
		}
	}
}
