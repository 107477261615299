.o-workplaceslider {
	$root: &;
	$bullet: #{$root}_bullet;

	&_bullet {
		max-width: rem(164);

		@include sp-down {
			max-width: rem(60);
		}

		#{$root}-comment & {
			margin-left: rem(124);
			max-width: rem(210);

			@include pc-down {
				max-width: rem(164);
			}

			@include tab-down {
				margin-left: 0;
				max-width: rem(120);
			}

			@include sp-down {
				max-width: rem(60);
			}
		}

		& + & {
			margin-left: rem(8);
		}
	}

	&_square {
		border: rem(1) solid $color-dim-gray2;
		box-sizing: border-box;
		display: inline-block;
		height: rem(9);
		position: relative;
		transform: rotate(45deg);
		width: rem(9);

		@include sp-down {
			height: rem(14.5);
			width: rem(14.5);
		}

		#{$bullet}:not(:last-child) & {
			margin-right: rem(164);

			@include sp-down {
				margin-right: rem(32);
			}

			&::before {
				background-color: $color-dim-gray2;
				bottom: rem(-16);
				content: '';
				height: rem(161);
				max-width: rem(.5);
				position: absolute;
				right: rem(-57);
				transform: rotate(45deg);
				width: 100%;

				@include sp-down {
					bottom: rem(8);
					height: rem(35);
					right: rem(-13);
				}

				#{$root}-comment & {
					bottom: rem(-19);
					height: rem(203);
					right: rem(-71);

					@include pc-down {
						bottom: rem(-12);
						height: rem(156);
						right: rem(-55);
					}

					@include tab-down {
						bottom: rem(-5);
						height: rem(112);
						right: rem(-39);
					}

					@include sp-down {
						bottom: rem(8);
						height: rem(35);
						right: rem(-13);
					}
				}
			}

			#{$root}-comment & {
				margin-right: rem(210);

				@include sp-down {
					margin-right: rem(32);
				}
			}
		}

		#{$bullet}-active & {
			&::after {
				background-color: $color-dim-gray2;
				content: '';
				height: rem(4.5);
				left: rem(1);
				position: absolute;
				top: rem(1);
				width: rem(4.5);

				@include sp-down {
					height: rem(7);
					left: rem(3);
					top: rem(3);
					width: rem(7);
				}

				#{$root}-comment & {
					height: rem(5);
					left: rem(3);
					top: rem(3);
					width: rem(5);

					@include sp-down {
						height: rem(7);
						left: rem(3);
						top: rem(3);
						width: rem(7);
					}
				}
			}
		}

		#{$root}-comment & {
			height: rem(13);
			width: rem(13);

			@include sp-down {
				height: rem(14.5);
				width: rem(14.5);
			}
		}
	}

	&_titlebullet {
		color: $color-arsenic2;
		display: block;
		font-family: $font-family-helveticaworld;
		font-size: rem(12);
		letter-spacing: .02em;
		line-height: rem(18);
		opacity: 0;
		text-align: center;
		transform: translateX(-50%);

		@include sp-medium-down {
			font-size: rem(10);
			line-height: rem(16);
		}

		@include sp-down {
			display: none;
		}

		#{$bullet}-active & {
			opacity: 1;
		}

		#{$root}-comment & {
			color: $color-taupe-gray;
			font-size: rem(14);
			line-height: rem(20);
			margin-top: rem(8);
			max-width: 220px;
			opacity: 1;

			@include tab-down {
				display: none;
			}

			p {
				color: $color-arsenic2;
				line-height: rem(20);
				margin-bottom: rem(4);
				@include u-fw-bold;
			}
		}
	}

	&_pagination {
		display: flex;
		justify-content: center;

		#{$root}-pagination-center & {
			padding-left: rem(164);

			@include sp-down {
				padding-left: 0;
			}
		}

		&.swiper-pagination-lock {
			display: none;
		}
	}

	&_thumbslide {
		display: flex;
		margin-bottom: rem(40);

		@include sp-medium-down {
			flex-wrap: wrap;
		}

		#{$root}-comment & {
			align-items: center;

			@include tab-down {
				flex-wrap: wrap;
			}
		}

		.a-image {
			margin-left: rem(57);
			width: calc(450 / #{$width-container-value} * 100%);

			@include sp-medium-down {
				margin: 0 rem(40) rem(27);
				width: 100%;
				@include aspect-ratio(296, 192);
			}

			#{$root}-comment & {
				max-width: rem(253);

				@include tab-up {
					margin-left: rem(95);
				}

				@include tab-down {
					margin: 0 auto rem(52) auto;

					@include aspect-ratio(297, 277);
				}
			}
		}
	}

	&_content {
		margin-left: rem(48);
		margin-right: rem(57);
		width: calc(100% - (450 / #{$width-container-value} * 100%));

		@include sp-medium-down {
			margin: 0 rem(40);
			width: 100%;
		}

		#{$root}-comment & {
			background: $color-white-smoke;
			border-radius: rem(14);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-left: rem(54);
			margin-right: rem(95);
			padding: rem(32) rem(37) rem(28);
			position: relative;

			@include sp-medium-up {
				width: 100%;
			}

			@include tab-down {
				margin: 0 rem(20);
			}

			.a-button {
				justify-content: left;
				margin-top: rem(20);
			}

			.a-heading {
				&_text {
					color: $color-arsenic2;
				}
			}
		}

		&::before {
			#{$root}-comment & {
				background: $color-white-smoke;
				content: '';
				display: block;
				height: rem(37);
				left: rem(-19);
				position: absolute;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
				width: rem(37);

				@include tab-down {
					left: 50%;
					top: rem(-19);
					transform: translateX(-50%) rotate(45deg);
				}
			}
		}

		&::after {
			display: none;
		}
	}

	&_title {
		margin-bottom: rem(20);

		@include sp-medium-down {
			margin-bottom: rem(12);
		}

		.a-text {
			color: $color-arsenic2;
		}

		#{$root}-comment & {
			.a-text {
				color: $color-taupe-gray;
			}
		}
	}

	&_description {
		color: $color-dim-gray2;

		.a-text {
			@include sp-medium-down {
				font-size: rem(16);
				line-height: rem(23);
			}
		}

		.a-icon {
			&:first-child {
				margin-right: rem(10);
				vertical-align: bottom;
			}

			&:last-child {
				margin-left: rem(10);
			}
		}

		#{$root}-comment & {
			font-family: $font-family-helveticaworld;
			font-size: rem(16);
			line-height: rem(23);

			.a-icon-quote-left {
				~ p:not(.a-icon) {
					display: inline;
				}
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		color: $color-arsenic4;
		transform: translateY(-100%);

		@include sp-medium-down {
			top: calc((100% - #{rem(224)})/2);
			transform: translateY(0);
		}

		@include sp-down {
			top: calc((100% - #{rem(310)})/2);
		}

		&::after {
			font-size: rem(24);

			@include sp-medium-down {
				font-size: rem(18);
			}
		}
	}

	&-comment {
		.swiper-button-prev,
		.swiper-button-next {
			left: auto;
			transform: translateY(-150%);
			width: auto;

			@include tab-down {
				display: none;
			}
		}
	}

	&_leftcontent {
		flex: 0 0 40%;
		max-width: 40%;
		padding-right: rem(20);
		text-align: center;

		@include tab-down {
			flex: 0 0 100%;
			max-width: 100%;
			padding-right: 0;
		}
	}

	&_rightcontent {
		color: $color-dim-gray2;
		flex: 0 0 60%;
		font-family: $font-family-helveticaworld;
		font-size: rem(16);
		line-height: rem(23);
		max-width: 60%;
		padding-left: rem(20);
		@include u-fw-regular;

		@include tab-down {
			flex: 0 0 100%;
			margin-top: rem(20);
			max-width: 100%;
			padding-left: 0;
		}
	}

	&_information {
		margin-bottom: rem(17);

		.a-heading {
			&_text {
				color: $color-arsenic2;
				@include u-fw-bold;
			}
		}
	}

	&_modal {
		display: flex;
		flex-wrap: wrap;

		.a-icon-quote-left {
			~ div:not(.a-icon) {
				display: inline;

				> div {
					display: inline;
				}
			}
		}

		@include tab-down {
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}
}
