.o-tagpanel {
	display: flex;
	flex-wrap: wrap;
	margin: rem(-5) 0;

	.a-tag {
		margin: rem(5) 0;

		&:not(:last-child) {
			margin-right: rem(10);
		}
	}
}
