.o-footer {
	$root: &;
	$animation: cubic-bezier(.4, 0, .2, 1);
	background-color: $color-black;
	color: $color-white;

	a {
		transition: .3s ease-in-out;

		&:hover {
			&,
			img {
				opacity: .7;
			}
		}

		.a-text {
			color: $color-white;
		}
	}

	ul,
	li {
		list-style: none;
	}

	&_top {
		padding-top: rem(5);

		@include tab-up {
			display: flex;
			flex-wrap: wrap;
			padding-top: rem(88);
		}
	}

	&_aboutsite,
	&_quicklinks,
	&_contact,
	&_logo {
		@include sp-medium-down {
			border-bottom: .5px solid $color-gainsboro;
			padding: rem(20) 0;
		}

		@include sp-down {
			padding-bottom: rem(40);
			padding-top: rem(40);
		}
	}

	&_aboutsite {
		@include tab-up {
			flex: 0 0 calc(478 / #{$width-container-value} * 100%);
			max-width: calc(478 / #{$width-container-value} * 100%);
			padding-right: calc(98 / #{$width-container-value} * 100%);
		}

		.a-text {
			color: $color-white;

			@include sp-medium-down {
				font-size: rem(10);
			}

			@include sp-down {
				font-size: rem(16);
				line-height: rem(23);
			}
		}
	}

	&_title {
		margin-bottom: rem(18);

		@include sp-down {
			margin-bottom: rem(24);
		}

		#{$root}_quicklinks & {
			cursor: pointer;
			position: relative;

			@include sp-medium-down {
				margin-bottom: 0;

				&::before {
					background-image: url('~assets/images/icons/white-right-arrow.svg');
					content: '';
					height: rem(20);
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%) rotate(-90deg);
					width: rem(20);
				}
			}

			@include sp-down {
				pointer-events: none;

				&::before {
					display: none;
				}
			}
		}

		#{$root}_quicklinks-closed & {
			@include sp-medium-down {
				&::before {
					transform: translateY(-50%) rotate(90deg);
				}
			}
		}
	}

	&_topdes {
		a {
			color: $color-brandeis-blue;
		}
	}

	&_veevaid {
		color: $color-spanish-gray;
		font-family: $font-family-basic;
		font-size: rem(12);
		letter-spacing: .02em;
		line-height: rem(18);
		margin-top: rem(41);
	}

	&_quicklinks {
		@include tab-up {
			flex: 0 0 calc(259 / #{$width-container-value} * 100%);
			max-width: calc(259 / #{$width-container-value} * 100%);
			padding-right: calc(98 / #{$width-container-value} * 100%);
		}
	}

	&_linkmenu {
		@include sp-medium-down {
			margin-top: rem(24);
		}

		#{$root}_quicklinks-closed & {
			display: none;
		}
	}

	&_linkitem {
		position: relative;

		@include sp-medium-down {
			padding: rem(8);
		}

		a {
			color: $color-white;

			.a-text {
				line-height: rem(23);
				transition: .3s ease-in-out;

				@include sp-down {
					font-size: rem(16);
				}

				&:hover {
					opacity: .7;
				}
			}
		}
	}

	&_contact {
		> .a-text {
			color: $color-white;
			margin-bottom: 0;
		}

		@include tab-up {
			flex: 0 0 calc(100% - ((478 + 259) / #{$width-container-value} * 100%));
			max-width: calc(100% - ((478 + 259) / #{$width-container-value} * 100%));
		}
	}

	&_contactlist {
		.a-text {
			color: $color-spanish-gray;
			margin-bottom: 0;

			> span:nth-of-type(2) {
				color: $color-white;
			}
		}
	}

	&_contactimage {
		.a-image {
			margin-top: rem(24);
			width: rem(132);
		}
	}

	&_socialmedia {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin-left: rem(-8);
		margin-right: rem(-8);
		margin-top: rem(10);

		@include sp-down {
			margin-bottom: rem(16);
		}
	}

	&_socialicon {
		flex: 0 0 rem(52);
		margin-top: rem(13);
		padding: 0 rem(8);

		> div,
		img {
			max-width: rem(40);

			@include tab-up {
				max-width: rem(50);
			}

			@include sp-down {
				max-width: rem(52);
			}
		}
	}

	&_socialpc {
		display: none;

		@include tab-up {
			display: block;
		}
	}

	&_socialmobile {
		@include tab-up {
			display: none;
		}
	}

	&_bottom {
		padding-bottom: rem(60);
		padding-top: 0;

		@include tab-up {
			align-items: flex-end;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: rem(30);
			padding-top: rem(30);
		}

		@include tab {
			margin: 0 rem(-6);
		}

		@include sp {
			padding-bottom: rem(55);
		}
	}

	&_logo {
		@include tab-up {
			flex: 0 0 calc(166 / #{$width-container-value} * 100%);
			max-width: calc(166 / #{$width-container-value} * 100%);
		}

		.a-image {
			max-width: rem(136);
		}
	}

	&_menu {
		@include tab-up {
			flex: 0 0 calc(100% - ((166 + 228) / #{$width-container-value} * 100%));
			max-width: calc(100% - ((166 + 228) / #{$width-container-value} * 100%));
		}

		@include tab {
			padding-left: rem(32);
		}
	}

	&_menuitem {
		@include tab-up {
			align-items: center;
			display: inline-flex;
		}

		@include sp-medium-down {
			margin-top: rem(21);
		}

		&:not(:last-child) {
			@include tab-up {
				margin-right: rem(58);
			}

			@include tab {
				margin-right: rem(30);
			}
		}

		a {
			color: $color-white;
		}

		.a-text {
			@include sp-down {
				font-size: rem(14);
			}
		}
	}

	&_copyright {
		@include sp-medium-down {
			margin-top: rem(21);
		}

		.a-text {
			color: $color-white;
		}
	}
}
