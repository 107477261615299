.o-geographicalmap {
	position: relative;

	&_tooltip {
		background: $color-white;
		border-radius: rem(10);
		filter: drop-shadow(0 0 rem(15) $color-gray-025);
		width: rem(160);

		@include pc {
			width: rem(190);
		}
	}

	&_title {
		background: $color-french-plum;
		border-radius: rem(10) rem(10) 0 0;
		color: $color-white;
		font-size: rem(14);
		line-height: rem(18);
		margin: 0;
		padding: rem(10);
		text-align: center;
		@include u-fw-medium;

		@include pc {
			font-size: rem(18);
			line-height: rem(24);
		}
	}

	&_amount {
		display: flex;
		font-size: rem(12);
		justify-content: space-between;
		line-height: rem(16);
		margin: 0;
		padding: rem(10);

		@include pc {
			font-size: rem(14);
			line-height: rem(20);
		}

		> span:last-child {
			margin-left: rem(10);
		}
	}

	.leaflet-container {
		margin: 0 auto;
	}

	.leaflet-control,
	.leaflet-popup-close-button {
		display: none;
	}

	.leaflet-zoom-animated {
		pointer-events: visible;
	}

	.leaflet-map-pane {
		position: relative;
	}

	.leaflet-popup-pane {
		position: absolute;
		top: 0;
	}

	.leaflet-proxy.leaflet-zoom-animated {
		display: none;
	}

	.leaflet-pane.leaflet-tooltip-pane {
		position: absolute;
		top: 50px;
		z-index: z('normal');

		@include sp {
			top: 35px;
		}
	}

	.leaflet-popup-content {
		font-size: rem(12);

		@include sp {
			margin-left: -90px;
			margin-top: -10px;
		}
	}

	.leaflet-pane.leaflet-overlay-pane {
		@include sp {
			/* stylelint-disable declaration-no-important */
			height: 420px !important;
		}

		svg {
			@include sp {
				overflow-x: hidden;
				width: 100% !important;
			}
		}
	}
}
