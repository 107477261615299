.m-orientation {
	$root: &;

	&_image {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: rem(160);
	}

	.a-heading {
		margin-top: rem(20);
		text-align: center;

		&_text {
			color: $color-arsenic2;
		}
	}

	.a-text {
		margin-top: rem(12);
	}
}
