/* @media
--------------------------------------------------------- */
// ---- col md ----
@mixin greater-than-md() {
	@media (min-width: $width-md + 1) {
		@content;
	}
}

// ---- pc medium ----
@mixin pc-medium-up() {
	@media (min-width: $width-pc + 1) {
		@content;
	}
}

// ---- pc ----
@mixin pc() {
	@media (min-width: $width-tab + 1) and (max-width: $width-pc) {
		@content;
	}
}

@mixin pc-up() {
	@media (min-width: $width-tab + 1) {
		@content;
	}
}

@mixin pc-down() {
	@media (max-width: $width-pc) {
		@content;
	}
}

// ---- tab ----
@mixin tab() {
	@media (min-width: $width-md) and (max-width: $width-tab) {
		@content;
	}
}

@mixin tab-up() {
	@media (min-width: $width-md) {
		@content;
	}
}

@mixin tab-down() {
	@media (max-width: $width-tab - 1) {
		@content;
	}
}

// ---- sp medium ----
@mixin sp-medium() {
	@media (min-width: $width-sm) and (max-width: $width-md - 1) {
		@content;
	}
}

@mixin sp-medium-up() {
	@media (min-width: $width-sm) {
		@content;
	}
}

@mixin sp-medium-down() {
	@media (max-width: $width-md - 1) {
		@content;
	}
}

// ---- sp ----
@mixin sp() {
	@media (min-width: 0) and (max-width: $width-sm - 1) {
		@content;
	}
}

@mixin sp-down() {
	@media (max-width: $width-sm - 1) {
		@content;
	}
}

/* There are three mixins apply for font-weight
--------------------------------------------------------- */
@mixin u-fw-thin {
	font-weight: 100;
}

@mixin u-fw-extlight {
	font-weight: 200;
}

@mixin u-fw-light {
	font-weight: 300;
}

@mixin u-fw-regular {
	font-weight: 400;
}

@mixin u-fw-medium {
	font-weight: 500;
}

@mixin u-fw-semibold {
	font-weight: 600;
}

@mixin u-fw-bold {
	font-weight: 700;
}

@mixin u-fw-extrabold {
	font-weight: 700;
}

@mixin u-fw-black {
	font-weight: 900;
}

/* There are three mixins apply for font-style
--------------------------------------------------------- */
@mixin u-fs-italic {
	font-style: italic;
}

/* There are three mixins apply for font-stretch
--------------------------------------------------------- */
@mixin u-fst-narrow {
	font-stretch: narrower;
}

/* make area that keep the aspect-ratio of area. This should be used with background-image
--------------------------------------------------------- */
@mixin aspect-ratio($width: 1, $height: 1) {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-bottom: ($height / $width) * 100%;
	}
}

@mixin scrollbars(
	$size,
	$foreground-color,
	$background-color: mix($foreground-color, $COLOR-WHITE, 50%)
) {
	// For Google Chrome
	&::-webkit-scrollbar {
		height: $size;
		width: $size;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $foreground-color;
		transition: background-color .3s ease-in-out;

		&:hover {
			background-color: mix($foreground-color, $color-black, 70%);
		}
	}

	&::-webkit-scrollbar-track {
		background-color: $background-color;
	}

	// For Internet Explorer
	scrollbar-face-color: $foreground-color;
	scrollbar-track-color: $background-color;
}

@mixin text-overflow($number: 2) {
	-webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
	display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
	-webkit-line-clamp: ($number);
	overflow: hidden;
	text-overflow: ellipsis;
}
