.o-header {
	$root: &;
	$animation: .3s ease-in-out;
	background-color: $color-white;
	font-family: $font-family-helveticaworld;

	a {
		text-decoration: none;
	}

	&_scrollmenu,
	&_topmain,
	&_toprightmain,
	&_language,
	&_globalwebsite,
	&_searchwrapper,
	&_search,
	&_hamburger,
	&_mainitem {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&_scrollmenuwrapper {
		background-color: $color-white;
		border-bottom: rem(1) solid $color-platinum;
		opacity: 0;
		transition: $animation;
		visibility: hidden;

		&:hover {
			#{$root}_scrolllogo, #{$root}_scrollhamburger {
				opacity: .5;
			}
		}

		#{$root}-pcsticky & {
			@include greater-than-md {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&_scrolllogo {
		transition: $animation;

		.a-image {
			&_img {
				display: block;
				height: rem(23);
				width: rem(18);
			}
		}
	}

	&_scrollhamburger {
		border-left: rem(.5) solid $color-silver-chalice;
		border-right: rem(.5) solid $color-silver-chalice;
		padding: rem(15) rem(16);
		transition: $animation;

		.a-icon {
			display: block;
			height: rem(14);
			width: rem(14);
		}
	}

	&_menucontent {
		background-color: $color-white;
		position: relative;
		transition: $animation;

		#{$root}-pcsticky & {
			@include greater-than-md {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	&_topmainwrapper {
		border-bottom: rem(1) solid $color-platinum;
	}

	&_topmain {
		padding: rem(26) 0;
		position: static;
		transition: $animation;

		@include greater-than-md {
			padding: rem(8) 0;
			position: relative;
		}

		#{$root}-opensearch & {
			@include greater-than-md {
				padding: rem(20) 0;
			}
		}
	}

	&_logo {
		transition: $animation;

		&:hover {
			opacity: .7;
		}

		.a-image {
			&_img {
				display: block;
				height: rem(28);
				width: rem(105);

				@include greater-than-md {
					height: rem(24);
					width: rem(96);
				}
			}
		}
	}

	&_website,
	&_globalwebsite {
		color: $color-sonic-silver;
		font-size: rem(10);
		line-height: rem(15);
		transition: $animation;

		&:hover {
			color: $color-french-plum;
		}
	}

	&_globalwebsite {
		.a-icon {
			margin-right: rem(6.38);
		}
	}

	&_languagegroup {
		display: none;

		@include greater-than-md {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		#{$root}_language {
			margin-right: rem(25);
			position: relative;
		}

		#{$root}_website {
			&::after,
			&::before {
				background-color: $color-anti-flash-white;
				content: '';
				display: inline-block;
				height: rem(16);
				position: absolute;
				width: rem(.98);
			}

			&::after {
				right: rem(-25);
			}

			&::before {
				left: rem(-25);
			}
		}

		#{$root}_globalwebsite {
			&::after {
				@extend #{$root}_website::after;
			}
		}

		#{$root}_website, #{$root}_globalwebsite {
			margin: 0 rem(25);
			position: relative;
		}
	}

	&_mobilelanguagegroup {
		border-top: rem(1) solid $color-platinum;
		padding: rem(10) 0 rem(30);

		@include greater-than-md {
			display: none;
		}

		#{$root}_language,
		#{$root}_website,
		#{$root}_globalwebsite {
			padding: rem(10) 0;
		}

		#{$root}_language,
		#{$root}_globalwebsite {
			justify-content: flex-start;
		}

		#{$root}_website {
			display: block;
		}
	}

	&_language {
		color: $color-battleship-grey;
		font-family: $font-family-helveticaworld;
		font-size: rem(10);
		line-height: rem(15);

		> .a-image {
			flex-shrink: 0;
			height: rem(20);
			margin-right: rem(6.6);
			width: rem(28);
		}
	}

	&_languagedropdown {
		// Fix safari
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
		appearance: none;
		background: url('~assets/images/icons/grey-down-angle.svg') no-repeat right center;
		border: 0;
		color: $color-battleship-grey;
		cursor: pointer;
		font-size: rem(10);
		line-height: rem(15);
		max-width: rem(44);
		padding-right: rem(11);
		text-overflow: ellipsis;
		width: fit-content;
	}

	&_searchwrapper {
		margin-left: rem(25);

		&::before,
		&::after {
			background-color: $color-silver-chalice;
			content: '';
			display: inline-block;
			height: rem(30);
			margin: 0 rem(22);
			width: rem(.5);

			@include greater-than-md {
				display: none;
			}
		}

		> .a-icon {
			filter: brightness(.2);
			height: rem(20);
			width: rem(20);

			@include greater-than-md {
				filter: unset;
				height: rem(17.5);
				width: rem(17.15);
			}
		}
	}

	&_search {
		background-color: $color-white;
		border-bottom: rem(1) solid $color-platinum;
		bottom: 0;
		flex-direction: row-reverse;
		left: 0;
		padding: rem(15);
		position: absolute;
		right: 0;
		transform-origin: bottom;
		transition: bottom $animation;
		z-index: z('header', 'searchbar');

		@include greater-than-md {
			border-bottom: 0;
			bottom: unset;
			flex-direction: unset;
			opacity: 0;
			padding: unset;
			right: rem(30);
			transform: scaleX(0);
			transform-origin: right;
			transition-property: all;
			visibility: hidden;
			z-index: unset;
		}

		> .a-icon {
			flex-shrink: 0;
			height: rem(17.5);
			margin-left: rem(22);
			margin-right: 0;
			width: rem(17.15);

			@include greater-than-md {
				margin-left: 0;
				margin-right: rem(27);
			}
		}

		#{$root}-opensearch & {
			bottom: rem(-70);

			@include greater-than-md {
				bottom: unset;
				opacity: 1;
				transform: scaleX(1);
				visibility: visible;
			}
		}
	}

	&_searchinputgroup {
		flex-grow: 1;
		position: relative;

		> div > .a-text {
			align-items: center;
			border-left: rem(.5) solid $color-silver-chalice;
			border-right: rem(.5) solid $color-silver-chalice;
			color: $color-silver-chalice;
			cursor: pointer;
			display: none;
			font-family: $font-family-basic;
			height: calc(100% - #{rem(1)});
			letter-spacing: .02em;
			padding: 0 rem(20);
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			transition: $animation;

			@include greater-than-md {
				display: flex;
			}

			&:hover {
				color: $color-french-plum;
			}
		}
	}

	&_searchinput {
		appearance: none;
		border: rem(1) solid $color-transparent;
		border-radius: rem(3);
		color: $color-dim-gray2;
		font-family: $font-family-basic;
		font-size: rem(14);
		height: rem(39);
		line-height: rem(18);
		outline: none;
		padding: rem(10) rem(97.72) rem(10) rem(17);
		transition: $animation;
		width: 100%;

		&::placeholder {
			color: $color-silver-chalice;
			font-family: $font-family-basic;
			font-size: rem(12);
			letter-spacing: .02em;
			line-height: rem(18);
		}

		&:focus,
		&:focus-within {
			border-color: $color-arsenic2;

			+ .a-text {
				border-right-color: $color-transparent;
				color: $color-french-plum;
			}
		}
	}

	&_hamburger {
		position: relative;

		@include greater-than-md {
			display: none;
		}

		.a-icon {
			height: rem(22);
			width: rem(22);
		}
	}

	&_bottommainwrapper {
		background-color: $color-white;
		height: 0;
		overflow: hidden;
		position: absolute;
		transition: $animation;
		width: 100%;

		&-open {
			height: calc(100vh - #{rem(83)});
		}

		&-showscroll {
			overflow: auto;
		}

		@include greater-than-md {
			height: unset;
			overflow: unset;
			position: static;
		}
	}

	&_bottommain {
		padding-bottom: rem(30);
		padding-top: rem(12);

		@include greater-than-md {
			padding-bottom: 0;
			padding-top: 0;
		}
	}

	&_mainitem,
	&_sublink {
		&::after {
			background-color: $color-french-plum;
			bottom: 0;
			content: '';
			height: rem(2);
			left: 0;
			position: absolute;
			transform: scale(0);
			transition: $animation;
			width: 100%;
		}

		&:hover,
		&-active {
			&::after {
				transform: scale(1);
			}
		}

		.a-text {
			font-family: $font-family-basic;
		}
	}

	&_mainitem {
		display: inline-flex;
		flex-wrap: wrap;
		margin-right: 0;
		padding: rem(10) 0;
		position: relative;
		width: 100%;

		@include greater-than-md {
			margin-right: rem(30);
			padding: rem(15) 0;
			width: unset;
		}

		@include pc-up {
			margin-right: rem(50);
		}

		&::after {
			display: none;
			z-index: z('header', 'mainmenuunderline');

			@include greater-than-md {
				display: block;
			}
		}

		&:hover,
		&-active {
			#{$root}_mainlink, #{$root}_mainlink#{$root}_maintrigger-active {
				.a-text {
					color: $color-french-plum;
				}
			}
		}

		&:hover {
			#{$root}_maindropdown {
				opacity: 1;
				transform: translateY(0);
				visibility: visible;
			}
		}
	}

	&_mainlink {
		align-items: center;
		display: flex;
		width: 100%;

		.a-text {
			color: $color-dim-gray2;
			line-height: rem(21);
			margin-bottom: 0;
			transition: $animation;
		}

		.a-icon {
			// For easy click
			background-position: right;
			background-size: rem(6.67);
			flex-grow: 1;
			margin-left: rem(8.67);
			padding: rem(10) 0;
			width: rem(7.5);

			@include greater-than-md {
				background-position: center;
				background-size: contain;
				padding: 0;
			}
		}

		&#{$root}_maintrigger-active {
			.a-text {
				color: $color-french-plum;

				@include greater-than-md {
					color: $color-dim-gray2;
				}
			}

			.a-icon {
				background-position: left;
				transform: rotate(180deg);

				@include greater-than-md {
					background-position: center;
					transform: unset;
				}
			}
		}
	}

	&_maindropdown {
		background-color: rgba($color-white, .9);
		flex: 0  0 100%;
		height: 0;
		left: 0;
		max-width: 100%;
		min-height: 0;
		overflow: hidden;
		padding: 0 rem(18) 0 rem(16);
		transition: $animation;
		z-index: z('normal');

		@include greater-than-md {
			flex: unset;
			height: unset;
			max-width: rem(200);
			min-height: unset;
			min-width: rem(127);
			opacity: 0;
			overflow: unset;
			position: absolute;
			top: 100%;
			transform: translateY(-10%);
			visibility: hidden;
			width: max-content;
		}

		#{$root}_dropdownlink {
			&:not(:last-of-type) {
				margin-bottom: rem(20);

				@include greater-than-md {
					margin-bottom: rem(16);
				}
			}

			&:first-of-type {
				margin-top: rem(13);
			}

			&:last-of-type {
				@include greater-than-md {
					margin-bottom: rem(13);
				}
			}
		}
	}

	&_dropdownlink {
		display: block;

		> .a-text {
			color: $color-taupe-gray;
			line-height: rem(16);
			transition: $animation;

			@include greater-than-md {
				color: $color-dark-liver;
			}
		}

		&:hover,
		&-active {
			.a-text {
				color: $color-french-plum;
			}
		}
	}

	&_submenu {
		background-color: rgba($color-anti-flash-white, .65);
		display: none;

		@include greater-than-md {
			display: block;
		}

		.container {
			#{$root}_sublink:not(:last-of-type) {
				margin-right: rem(45);
			}
		}
	}

	&_sublink {
		display: inline-block;
		padding: rem(10) 0;
		position: relative;

		&::after {
			display: block;
		}

		&:hover {
			.a-text {
				color: $color-french-plum;
			}
		}

		.a-text {
			color: $color-battleship-grey;
			line-height: rem(21);
			transition: color $animation;
		}
	}

	&-pcsticky {
		&:hover {
			#{$root}_menucontent {
				@include greater-than-md {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
