.m-jumbto {
	list-style: none;

	&_item {
		padding: rem(12) 0;

		& + & {
			border-top: rem(1) solid $color-gainsboro;
		}

		&:last-child {
			border-bottom: rem(1) solid $color-gainsboro;
		}
	}

	&_link {
		cursor: pointer;
		display: flex;
		transition: opacity .3s ease-in-out;

		&:hover {
			opacity: .7;
			text-decoration: none;
		}

		.a-icon {
			flex: 0 0 auto;
			height: rem(24);
			width: rem(24);
		}

		.a-heading {
			color: $color-arsenic2;
			margin-left: rem(12);
		}
	}
}
