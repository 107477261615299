.o-list {
	$root: &;

	&-statistic {
		display: flex;
		flex-wrap: wrap;
		margin: rem(-10) 0;

		&:not(#{$root}-threeColumnsPerRow) {
			.m-listitem-statistic {
				flex: 0 0 100%;
				padding: rem(10) 0;

				@include pc-up {
					flex: 0 0 25%;
				}

				@include tab {
					flex: 0 0 50%;
				}
			}
		}

		&#{$root}-threeColumnsPerRow {
			.m-listitem-statistic {
				flex: 0 0 calc(100% / 3);
				padding: rem(10) 0;

				@include sp-medium-down {
					flex: 0 0 100%;
				}
			}
		}
	}
}
