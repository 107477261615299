.a-tag {
	$root: &;
	align-items: center;
	border: 1px solid $color-silver-chalice;
	display: inline-flex;
	padding: rem(8) rem(12);
	transition: all .3s ease-in-out;

	&_content {
		color: $color-arsenic2;
		font-size: rem(12);
		line-height: rem(18);

		.a-text {
			font-family: $font-family-basic;
			transition: all .3s ease-in-out;

			#{$root}:not(#{$root}-bordered) & {
				@include u-fw-medium;
				@include u-fs-italic;
			}

			#{$root}-bordered & {
				color: $color-black;
				line-height: rem(24);
			}

			#{$root}-clickable:hover & {
				color: $color-white;
			}

			#{$root}-selected & {
				color: $color-white;
			}
		}
	}

	.a-icon {
		flex: 0 0 auto;
		height: rem(10);
		margin-left: rem(10);
		width: rem(10);
	}

	&-bordered {
		border: .5px solid $color-silver-chalice;
		border-radius: rem(20);
		padding: rem(5) rem(12);
	}

	&-clickable {
		cursor: pointer;

		&:hover {
			background-color: $color-french-plum;
			border-color: $color-french-plum;
		}
	}

	&-selected {
		background-color: $color-french-plum;
		border-color: $color-french-plum;
	}
}
