.t-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.o-header {
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: z('header', 'headerwrapper');

		@include greater-than-md {
			position: relative;
		}

		&_scrollmenuwrapper {
			left: 0;
			position: fixed;
			right: 0;
			top: rem(-87);
		}

		&-pcsticky {
			&:hover {
				.o-header_menucontent {
					@include greater-than-md {
						top: 0;
					}
				}
			}

			.o-header_scrollmenuwrapper {
				@include greater-than-md {
					top: 0;
				}
			}

			.o-header_menucontent {
				@include greater-than-md {
					left: 0;
					position: fixed;
					right: 0;
					top: rem(-150);
				}
			}

			+ .o-main {
				@include greater-than-md {
					margin-top: rem(92);
				}
			}

			&.o-header-opensub {
				+ .o-main {
					@include greater-than-md {
						margin-top: rem(133);
					}
				}
			}
		}
	}

	.o-main {
		display: flex;
		flex: 1;
		margin-top: rem(83);

		@include greater-than-md {
			margin-top: 0;
		}
	}

	.o-main > .o-general,
	.o-main > .u-homepageskeleton {
		width: 100%;
	}
}
