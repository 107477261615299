.u-homepageskeleton {
	$root: &;

	&_banner {
		&::before {
			background: $color-banner-background;
			mix-blend-mode: multiply;
		}

		@include aspect-ratio(1366, 452);

		@include sp-medium-down {
			@include aspect-ratio(375, 230);
		}

		@include tab {
			@include aspect-ratio(768, 230);
		}
	}

	&_captionblocks {
		margin: rem(60) auto;
		max-width: 1156px;
		padding-left: 15px;
		padding-right: 15px;
	}

	&_captionblockprimary {
		position: relative;

		&::before {
			background: $color-banner-background;
			mix-blend-mode: multiply;
		}

		@include aspect-ratio(740, 416);
	}

	&_captionblocksecondary {
		position: relative;

		&::before {
			background: $color-banner-background;
			mix-blend-mode: multiply;
		}

		@include aspect-ratio(354, 192);
	}

	&_captionblockcontent {
		background-color: $color-snow-08;
		border-bottom: 5px solid $color-french-plum;
		bottom: 0;
		display: inline-block;
		left: 0;
		padding: rem(13) rem(27);
		position: absolute;
		right: 0;
		width: 100%;
		z-index: z('normal');

		@include sp {
			background-color: $color-gainsboro;
			border-bottom-width: 7px;
			padding: rem(16) rem(16) rem(10);
			position: relative;
		}

		#{$root}_captionblockprimary & {
			@media (min-width: 1024px) {
				/* stylelint-disable-next-line declaration-no-important */
				border-bottom: 0 !important;
				border-top: 5px solid $color-french-plum;
				bottom: unset;
				top: 0;
			}
		}

		#{$root}_captionblocksecondary & {
			padding: rem(16);

			@include sp {
				padding: rem(16) rem(16) rem(10);
			}
		}
	}

	&_captionblockcaption {
		background-color: $color-snow;
		border-radius: 20px;
		display: block;
		height: 24px;

		@include sp {
			height: 20px;
		}

		& + & {
			margin-top: 6px;

			@include sp {
				margin-top: 4px;
			}
		}

		#{$root}_captionblocksecondary & {
			height: 20px;

			& + & {
				margin-top: 4px;
			}
		}
	}

	&_captionblocklearnmore {
		background-color: $color-snow;
		border-radius: 20px;
		display: inline-block;
		height: 24px;
		margin-top: 11px;
		max-width: 120px;
		width: 100%;
	}
}
