.a-video {
	$root: &;
	@include aspect-ratio(643, 361);

	&_reactplayer {
		left: 0;
		position: absolute;
		top: 0;
		z-index: z('normal');

		#{$root}-overlay & {
			.react-player__preview {
				position: relative;

				&::before {
					background: $color-black-025;
					bottom: 0;
					content: '';
					height: 100%;
					left: 0;
					position: absolute;
					right: 0;
					width: 100%;
					z-index: z('normal');
				}
			}
		}
	}

	&_button {
		cursor: pointer;
		height: rem(40);
		transition: opacity .3s ease-in-out;
		width: rem(40);
		z-index: z('normal');

		@include pc-up {
			height: rem(52);
			width: rem(52);
		}

		#{$root}-whiteicon & {
			height: rem(30);
			width: rem(30);

			@include pc-up {
				height: rem(44);
				width: rem(44);
			}
		}

		&:hover {
			opacity: .7;
		}
	}
}
