.a-filterbutton {
	appearance: none;
	background: $color-transparent;
	border: .5px solid $color-dim-gray2;
	border-radius: rem(20);
	box-sizing: border-box;
	color: $color-arsenic2;
	font-size: rem(12);
	line-height: rem(14);
	outline: none;
	padding: rem(5) rem(12);
	transition: all .3s ease-in-out;
	@include u-fs-italic;

	&:active,
	&:focus {
		background: $color-french-plum;
		border: .5px solid $color-french-plum;
		color: $color-white;
	}

	&:hover {
		border-color: $color-french-plum;
	}

	&-disabled {
		opacity: .7;
		pointer-events: none;
	}
}
