.a-heading {
	$root: &;

	&_text {
		margin-bottom: 0;
		@include u-fw-regular;

		#{$root}-type-h1 & {
			font-size: rem(42);
			line-height: rem(60);
		}

		#{$root}-type-h2 & {
			font-size: rem(24);
			line-height: rem(30);
		}

		#{$root}-type-h3 & {
			font-size: rem(20);
			line-height: rem(24);
		}

		#{$root}-font-weight-medium & {
			@include u-fw-medium;
		}

		#{$root}-font-weight-semibold & {
			@include u-fw-semibold;
		}

		#{$root}-font-weight-bold & {
			@include u-fw-bold;
		}

		#{$root}-font-style-italic & {
			@include u-fs-italic;
		}

		#{$root}-centered & {
			text-align: center;
		}
	}
}
