.m-captionblock {
	$root: &;
	position: relative;

	&:hover .a-heading {
		text-decoration: underline;
	}

	@include sp {
		/* stylelint-disable-next-line declaration-no-important */
		border-bottom-width: 7.5px !important;
	}

	&_content {
		background: $color-snow-08;
		bottom: 0;
		left: 0;
		padding: rem(13) rem(27);
		position: absolute;
		right: 0;
		width: 100%;
		z-index: z('normal');

		@include sp {
			background: $color-snow;
			padding: rem(16) rem(16) rem(10);
			position: relative;
		}

		#{$root}-primary & {
			@media (min-width: 1024px) {
				bottom: unset;
				top: 0;
			}
		}

		#{$root}-secondary & {
			padding: rem(16);

			@include sp {
				padding: rem(16) rem(16) rem(10);
			}
		}
	}

	&-primary {
		@media (min-width: 1024px) {
			/* stylelint-disable-next-line declaration-no-important */
			border-bottom: 0 !important;
		}

		@media (max-width: 1023px) {
			/* stylelint-disable-next-line declaration-no-important */
			border-top: 0 !important;
		}
	}

	.a-heading {
		@include text-overflow (3);

		a {
			color: $color-arsenic2;

			@include sp {
				font-size: rem(20);
				line-height: rem(24);
			}
		}
	}

	.a-button {
		// TODO: Missing font-family Roboto
		color: $color-quinacridone-magenta;
		margin-top: rem(11);
	}

	.a-button_iconwrapper {
		margin-left: rem(8);
	}
}
