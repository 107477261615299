.m-descriptionicon {
	.a-image {
		border-radius: 50%;
		margin: 0 auto;
		max-width: rem(100);
	}

	.a-text {
		color: $color-taupe-gray;
		font-family: $font-family-basic;
		margin-top: rem(20);
	}
}
