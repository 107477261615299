.m-leadershipteam {
	.a-image {
		width: calc(173 / 265 * 100%);
	}

	.a-heading {
		color: $color-arsenic2;
		margin-top: rem(20);
	}

	.a-text {
		color: $color-dim-gray2;
		margin-top: rem(10);
	}
}
