.m-pagination {
	&_pages {
		display: flex;
		list-style: none;
	}

	&_previous,
	&_next,
	&_page {
		color: $color-dim-gray2;
		transition: .3s ease-in-out;

		&:hover {
			color: $color-french-plum;
		}

		> a {
			font-family: $font-family-helveticaworld;
			letter-spacing: .02em;
			line-height: rem(23);
		}
	}

	&_previous,
	&_next {
		&.disabled {
			color: $color-silver-chalice;
			pointer-events: none;
		}
	}

	&_previous {
		margin-right: rem(.5);
	}

	&_next {
		margin-left: rem(.5);
	}

	&_page {
		margin: 0 rem(3.5);

		> a {
			padding: 0 rem(6);
		}

		&-active {
			color: $color-french-plum;
		}
	}
}
