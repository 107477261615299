.m-table {

	$root: &;
	margin: auto;
	overflow: auto;

	@include scrollbars(6px, $color-gainsboro, $color-white);

	&::-webkit-scrollbar {
		// Fix safari
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-webkit-appearance: none;
		-webkit-overflow-scrolling: touch;
	}

	&::-webkit-scrollbar-thumb {
		&:hover {
			background-color: $color-french-plum;
		}
	}

	&_table {
		border-collapse: separate;
		border-spacing: 0;
		table-layout: fixed;
		width: 100%;
	}

	&_th {
		background: $color-french-plum;
		color: $color-white;
		font-size: rem(18);
		line-height: rem(24);
		padding: rem(14) rem(10) rem(15) rem(10);
		@include u-fs-italic;
		@include u-fw-medium;

		& ~ & {
			border-left: rem(1) solid $color-white;
		}

		&:first-of-type {
			border-top-left-radius: rem(10);
		}

		&:last-of-type {
			border-top-right-radius: rem(10);
		}
	}

	&_td {
		background-color: $color-white-smoke;
		border: rem(.5) solid  $color-dim-gray2;
		border-style: none none solid solid;
		color: $color-dim-gray2;
		font-family: $font-family-helveticaworld;
		font-size: rem(14);
		letter-spacing: .02em;
		line-height: rem(28);
		padding: rem(13) rem(7);
		vertical-align: top;

		&:first-of-type {
			#{$root}_tr:last-of-type & {
				border-bottom-left-radius: rem(10);
			}
		}

		&:last-of-type {
			border-right-style: solid;

			#{$root}_tr:last-of-type & {
				border-bottom-right-radius: rem(10);
			}
		}
	}
}
