.o-banner {
	$root: &;
	position: relative;
	width: 100%;

	&_inner {
		&::before {
			background: $color-banner-background;
			mix-blend-mode: multiply;
			position: relative;
			z-index: z('normal');
		}

		@include aspect-ratio(1366, 410);

		@include sp-medium-down {
			@include aspect-ratio(375, 112.55);
		}

		@include tab {
			@include aspect-ratio(768, 230);
		}

		#{$root}-homepage & {
			@include aspect-ratio(1366, 452);

			@include sp-medium-down {
				@include aspect-ratio(375, 112.55);
			}

			@include tab {
				@include aspect-ratio(768, 230);
			}
		}

		#{$root}-footer & {
			@include aspect-ratio(1366, 497);

			@include sp-medium-down {
				@include aspect-ratio(375, 280);
			}

			@include tab {
				@include aspect-ratio(770, 497);
			}
		}

		#{$root}-inline & {
			@include aspect-ratio(1366, 235);

			@include sp-medium-down {
				@include aspect-ratio(334, 56.36);
			}

			@include tab {
				@include aspect-ratio(708, 119);
			}
		}

		#{$root}-inline-fluid & {
			@include aspect-ratio(1125, 190);

			@include sp-medium-down {
				@include aspect-ratio(334, 56.36);
			}

			@include tab {
				@include aspect-ratio(708, 119);
			}
		}

		#{$root}-latest-news-detail & {
			@include aspect-ratio(1366, 375);

			@include sp-medium-down {
				@include aspect-ratio(375, 210);
			}

			@include tab {
				@include aspect-ratio(767, 210);
			}
		}
	}

	&_imagewrapper {
		clip: rect(0, auto, auto, 0);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&_image {
		height: 100%;
		width: 100%;

		#{$root}:not(#{$root}-useplayer) & {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		#{$root}-useParallaxScrolling:not(#{$root}-useplayer) & {
			background-attachment: fixed;
		}

		#{$root}-useParallaxScrolling#{$root}-useIPhoneIPad:not(#{$root}-useplayer) & {
			background-attachment: scroll;
			display: block;
			height: 100%;
			left: 0;
			position: fixed;
			top: 0;
			transform: translateZ(0);
			width: 100%;
			will-change: transform;
			z-index: z('normal');
		}

		#{$root}-hasVideo & {
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;

			.a-icon {
				cursor: pointer;
				height: rem(70);
				left: 50%;
				margin-left: rem(130);
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				transition: all .15s ease-in;
				width: rem(70);
				z-index: z('banner', 'modal');

				&:hover {
					transform: translate(-50%, -50%) scale(1.1, 1.1);
				}

				@include tab-down() {
					height: rem(55);
					margin-left: 0;
					width: rem(55);
				}
			}
		}
	}

	&_container {
		&-inner {
			align-items: center;
			bottom: 0;
			display: flex;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		&-outer {
			margin-top: rem(20);
		}

		#{$root}-righttoleft & {
			justify-content: flex-end;
		}

		#{$root}-inline & {
			padding: 0;
		}

		#{$root}-footer & {
			@include sp-medium-down {
				margin: 0;
				padding: 0;
			}
		}
	}

	&_content {
		z-index: z('banner', 'content');

		#{$root}-latest-news-detail & {
			@include tab {
				display: none;
			}
		}

		#{$root}-homepage & {
			max-width: rem(668);
		}

		#{$root}-useplayer & {
			position: relative;
			z-index: z('banner', 'content');
		}

		#{$root}-inline & {
			margin-left: calc(95 / #{$width-container-value} * 100%);
		}
	}

	&_title {
		.a-heading_text {
			@media (min-width: 1000px) {
				color: $color-white;
			}

			@include sp-medium-down {
				color: $color-arsenic2;
				font-size: rem(30);
				line-height: rem(36);
			}
		}
	}

	&_description {
		font-family: $font-family-helveticaworld;

		@media (min-width: 1000px) {
			color: $color-white;
		}

		@include sp-medium-down {
			color: $color-dim-gray2;
		}

		@include sp-down {
			font-size: rem(14);
			line-height: rem(20);
		}

		#{$root}-homepage & {
			margin-top: rem(15);
		}
	}

	&_player {
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: z('banner', 'player');
	}

	&_reactplayer {
		left: 0;
		position: absolute;
		top: 0;
		z-index: z('normal');
	}
}
